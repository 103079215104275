import "./portalusers.scss";
import {
  Table,
  Button,
  Search,
  Modal,
  Form,
  Pagination,
  Dropdown,
  Checkbox,
  Message,
} from "semantic-ui-react";
import Skeleton from "react-skeleton-loader";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom Functions
import { emailValidate } from "../../lib/emailValidation";
import { userRole } from "../../lib/user_role";
import { languageOptions } from "../../lib/language-options";
import { expirationSession } from "../../lib/expirationSession";

// API
import {
  PagesCount as API_PageCount,
  Page as API_Page,
  User as API_User,
  Password as API_Password,
  Save as API_Save,
  Delete as API_Delete,
  Summary as API_Summary,
  Export as API_Export
} from "../../api/portalusers";

// Components
import Loading from "../../components/shared/LoadingScreen";

//  Redux
import { connect } from "react-redux";
import { SetUsername, SetToken, SetPortalMessage  } from "../../redux/global/actions";
import {
  SetAllRecords,
  SetCurrentRecord,
  SetModal,
  SetFilter,
  SetColumnActive,
  SetColumnOrder,
  SetCurrentPage,
  SetTotalPages,
  SetSummary,
  SetFormMessage
} from "../../redux/portalusers/actions";
import { SetLoginFormError } from "../../redux/login/actions";

// ----------------------------------------------------------------
function PortalUsers(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  // Check Value
  const isNull = (value) => {
    if (value === null) {
      return (
        <Skeleton
          color="var(--tp_skeleton_color)"
          width="20px"
          widthRandomness="0"
        />
      );
    } else {
      return value;
    }
  };
  // ---------

  // API error
  const fncAPIGetError = (error) => {
    if (error.response === undefined || null) {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (500).",
      });
    } else if (error.response.status === 401) {
      expirationSession(props, t, navigation);
    } else {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
      });
    }
  };
  // ---------

  // Change Column Order
  var fncChangeColumnOrder = () => {
    return props.portalusers.columnOrder === "asc" ? "desc" : "asc";
  };
  // ---------

  // Check Username alredy exist
  var fncCheckUsername = (string,fncComplete) => {
  API_Page(
      props.global.username,
      props.global.token,
      1,
      string,
      props.portalusers.columnActive,
      props.portalusers.columnOrder
    )
      .then((body) => {
        if (body.data.portal_users.length > 0) {
          fncComplete(false);
        } else {
          fncComplete(true);
        }
      })
      .catch(() => {
        fncComplete(false);
      });
  }
  // ---------

  // Get Rows
  var fncGetRows = () => {
    let rows = [];
    props.portalusers.allRecords.forEach((element, index) => {
      rows.push(
        <Table.Row key={index}>
          <Table.Cell textAlign="center">{element.name}</Table.Cell>
          <Table.Cell textAlign="center">{element.surname}</Table.Cell>
          <Table.Cell textAlign="center">{element.username}</Table.Cell>
          <Table.Cell textAlign="center">
            {element.permissions === 1 ? "Admin" : "SubAdmin"}
          </Table.Cell>
          <Table.Cell collapsing>
            <Button
              icon="pencil"
              circular
              onClick={() => {fncUser(element);props.SetFormMessage(null);}}
            />
            <Button
              icon="trash"
              circular
              onClick={() => {fncDelete(element)}}
            />
            <Button
              icon="key"
              circular
              onClick={() =>{
                props.SetCurrentRecord(element);
                props.SetModal({
                  open: true,
                  type: "changepassword",
                });
                props.SetFormMessage(null);
              }
                
              }
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return rows;
  };
  // ---------
  
  // -------------------------------

  const fncUpdateGrid = () => {
    // Summary
    API_Summary(
      props.global.username,
      props.global.token
    )
      .then((body) => {
        props.SetSummary(body.data);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    // Page
    API_Page(
      props.global.username,
      props.global.token,
      props.portalusers.currentPage,
      props.portalusers.filter,
      props.portalusers.columnActive,
      props.portalusers.columnOrder
    )
      .then((body) => {
        props.SetAllRecords(body.data.portal_users);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    // Page Count
    API_PageCount(
      props.global.username,
      props.global.token,
      props.portalusers.filter
    )
      .then((body) => {
        props.SetTotalPages(body.data.pages_count);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });
  };

  // Delete
  const fncDelete = (element) => 
  API_Delete(
    props.global.username,
    props.global.token,
    element.username
  )
    .then(() => {
      fncUpdateGrid();
      props.SetPortalMessage({
        error: false,
        content: t("Actions.Deleted"),
      });
    })
    .catch((error) => {
      if (error.response === undefined || null) {
        props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
      } else if (error.response.status === 401) {
        expirationSession(props, t, navigation);
      } else {
        props.SetPortalMessage({
          error: true,
          content: t("PortalMessages.Error.DeleteError"),
        });
      }
    });

  // Save
  const fncSave = () => {
    API_Save(
      props.global.username,
      props.global.token,
      props.portalusers.currentRecord.name,
      props.portalusers.currentRecord.surname,
      props.portalusers.currentRecord.username,
      props.portalusers.currentRecord.password,
      props.portalusers.currentRecord.permissions,
      props.portalusers.currentRecord.notifications,
      props.portalusers.currentRecord.lang
    )
      .then(() => {
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Saved"),
        });
        props.SetModal(
          Object.assign(props.portalusers.modal, {
            open: false,
          })
        );
        props.SetFormMessage(null);
        fncUpdateGrid();
      })
      .catch((error) => {
        fncAPIGetError(error);
      });
  };

  // Change Password
  const fncChangePassword = () => {
    API_Password(
      props.global.username,
      props.global.token,
      props.portalusers.currentRecord.username,
      props.portalusers.currentRecord.password
    )
      .then(() => {
        props.SetFormMessage(null);
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Saved"),
        });
        props.SetModal(
          Object.assign(props.portalusers.modal, {
            open: false,
          })
        );
      })
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetFormMessage(t("PortalMessages.Error.PasswordPolicy"));
        }
      });
  };

  // User
  const fncUser = (element) => {
    API_User(props.global.username, props.global.token, element.username)
      .then((body) => {
        props.SetCurrentRecord(body.data);
        props.SetModal({ open: true, type: "edit" });
      })
      .catch((error) => {
        fncAPIGetError(error);
      });
  }

  // Export
  const fncExport = () => {
    API_Export(props.global.username, props.global.token)
    .then((body) => {
      window.location.replace(process.env.REACT_APP_API_URI + body.data.url)
    })
    .catch((error) => {
      fncAPIGetError(error);
    });
  }

  useEffect(() => {
    fncUpdateGrid();
  }, [
    props.portalusers.filter,
    props.portalusers.currentPage,
    props.portalusers.columnActive,
    props.portalusers.columnOrder,
  ]);



  // ------------------------------------------------------------------------

  if (props.portalusers === undefined || props.portalusers === null) {
    return <Loading />;
  } else {
    return (
      <div id="tp_portalusers_ctr">
        <div id="tp_managebar_ctr">
          <div id="tp_filters_ctr">
            <div className="tp_filter_btn">
              <strong>Admin</strong>
              <p>{isNull(props.portalusers.summary.admin_count)}</p>
            </div>
            <div className="tp_filter_btn">
              <strong>SubAdmin</strong>
              <p>{isNull(props.portalusers.summary.subadmin_count)}</p>
            </div>
          </div>
          <div id="tp_btn_ctr">
            <Search
              placeholder={t("Input.Search")}
              size="large"
              defaultValue={props.portalusers.filter}
              showNoResults={false}
              onSearchChange={(e, d) => {
                props.SetFilter(d.value);
              }}
            />
            <Button icon='file excel' circular size="large" onClick={() => fncExport()} />
            <Button icon='add' circular size="large" onClick={() =>{
                props.SetModal({
                  open: true,
                  type: "create",
                  record: 0,
                });
                props.SetFormMessage(null);
              }}
              />
          </div>
        </div>
        <div id="tp_table_ctr">
          {props.portalusers.allRecords === null ? (
            <Loading />
          ) : (
            <Table sortable unstackable selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.portalusers.columnActive === "name"
                        ? props.portalusers.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("name");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("PortalUsers.Name")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.portalusers.columnActive === "surname"
                        ? props.portalusers.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("surname");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("PortalUsers.Surname")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.portalusers.columnActive === "username"
                        ? props.portalusers.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("username");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("PortalUsers.Email")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.portalusers.columnActive === "permissions"
                        ? props.portalusers.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("permissions");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("PortalUsers.Role")}
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>{fncGetRows()}</Table.Body>

              {props.portalusers.totalPages !== 1 ? (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5" textAlign="right">
                      <Pagination
                        defaultActivePage={props.portalusers.currentPage}
                        totalPages={props.portalusers.totalPages}
                        onPageChange={(e, d) =>
                          props.SetCurrentPage(d.activePage)
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              ) : null}
            </Table>
          )}
        </div>
        <Modal
          onClose={() =>
            props.SetModal(
              Object.assign(props.portalusers.modal, { open: false })
            )
          }
          open={props.portalusers.modal.open}
        >
          {props.portalusers.modal.type === "edit" ? (
            <>
              <Modal.Header>{t("PortalUsers.EditUser")}</Modal.Header>
              <Modal.Content>
              {props.portalusers.formMessage !== null ? (
                <Message error content={props.portalusers.formMessage} />
              ) : null}
                <Form onChange={() => props.SetFormMessage(null)}>
                <div className="tp_form_flex">
                    <Form.Input
                      label={t("PortalUsers.Name")}
                      defaultValue={props.portalusers.currentRecord.name}
                      required
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            name: d.value,
                          })
                        )
                      }
                    />
                    <Form.Input
                      label={t("PortalUsers.Surname")}
                      defaultValue={props.portalusers.currentRecord.surname}
                      required
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            surname: d.value,
                          })
                        )
                      }
                    />
                  </div>
                  <Form.Input
                    label={t("PortalUsers.Email")}
                    defaultValue={props.portalusers.currentRecord.username}
                    required
                    disabled={true}
                    onChange={(e, d) =>
                      props.SetCurrentRecord(
                        Object.assign(props.portalusers.currentRecord, {
                          username: d.value,
                        })
                      )
                    }
                  />
                  <div className="tp_form_flex">
                    <Form.Dropdown
                      label={t("PortalUsers.Role")}
                      control={Dropdown}
                      selection
                      options={userRole}
                      defaultValue={
                        props.portalusers.currentRecord.permissions === 1
                          ? userRole[0].value
                          : userRole[1].value
                      }
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            permissions: d.value,
                          })
                        )
                      }
                    />
                    <Form.Dropdown
                      label={t("PortalUsers.Lang")}
                      control={Dropdown}
                      selection
                      options={languageOptions}
                      defaultValue={props.portalusers.currentRecord.lang}
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            lang: d.value,
                          })
                        )
                      }
                    />
                  </div>
                  <Form.Checkbox
                    label={t("PortalUsers.Notification")}
                    control={Checkbox}
                    toggle={true}
                    checked={props.portalusers.currentRecord.notifications}
                    onChange={(e, d) =>
                      props.SetCurrentRecord(
                        Object.assign(props.portalusers.currentRecord, {
                          notifications: d.checked,
                        })
                      )
                    }
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={t("Button.Cancel")}
                  onClick={() =>{
                    props.SetModal({
                      open: false,
                      type: "",
                    })
                    props.SetFormMessage(null);
                  }}
                />
                <Button
                  content={t("Button.Save")}
                  onClick={() => {
                    if (props.portalusers.currentRecord.name && props.portalusers.currentRecord.surname && props.portalusers.currentRecord.username) {
                      if (emailValidate(props.portalusers.currentRecord.username)) {
                        fncSave();
                      } else {
                        props.SetFormMessage(t("PortalMessages.Error.MailNotValid"));
                      }
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                    }
                  }}
                  className="tp_btn"
                />
              </Modal.Actions>
            </>
          ) : props.portalusers.modal.type === "create" ? (
            <>
              <Modal.Header>{t("PortalUsers.CreateUser")}</Modal.Header>
              <Modal.Content>
              {props.portalusers.formMessage !== null ? (
              <Message error content={props.portalusers.formMessage} />
            ) : null}
                <Form onChange={() => props.SetFormMessage(null)}>
                  <div className="tp_form_flex">
                    <Form.Input 
                      label={t("PortalUsers.Name")} 
                      required
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            name: d.value,
                          })
                        )
                      }
                      />
                    <Form.Input
                    label={t("PortalUsers.Surname")}
                    required
                    onChange={(e, d) =>
                      props.SetCurrentRecord(
                        Object.assign(props.portalusers.currentRecord, {
                          surname: d.value,
                        })
                      )
                    }
                    />
                  </div>
                  <Form.Input 
                    label={t("PortalUsers.Email")}
                    required
                    onChange={(e, d) =>
                    props.SetCurrentRecord(
                      Object.assign(props.portalusers.currentRecord, {
                        username: d.value,
                      })
                    )}
                  />
                  <div className="tp_form_flex">
                    <Form.Input
                      label={t("PortalUsers.Password")}
                      type={"password"}
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            password: d.value,
                          })
                        )
                      }
                    />
                    <Form.Input
                      label={t("PortalUsers.ConfirmPassword")}
                      type={"password"}
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            confirmPassword: d.value,
                          })
                        )
                      }
                    />
                  </div>
                  <div className="tp_form_flex">
                    <Form.Dropdown
                      label={t("PortalUsers.Role")}
                      control={Dropdown}
                      selection
                      options={userRole}
                      defaultValue={2}
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            permissions: d.value,
                          })
                        )
                      }
                    />
                    <Form.Dropdown
                      label={t("PortalUsers.Lang")}
                      control={Dropdown}
                      selection
                      options={languageOptions}
                      defaultValue={"en"}
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            lang: d.value,
                          })
                        )
                      }
                    />
                  </div>
                  <Form.Checkbox
                    label={t("PortalUsers.Notification")}
                    control={Checkbox}
                    toggle={true}
                    onChange={(e, d) =>
                      props.SetCurrentRecord(
                        Object.assign(props.portalusers.currentRecord, {
                          notifications: d.checked,
                        })
                      )
                    }
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={t("Button.Cancel")}
                  onClick={() => {
                    props.SetModal(
                      Object.assign(props.portalusers.modal, {
                        open: false,
                        type: "",
                      })
                    );
                    props.SetFormMessage(null);
                  }}
                />
                <Button
                  content={t("Button.Create")}
                  onClick={() => {
                    if (props.portalusers.currentRecord.name && props.portalusers.currentRecord.surname && props.portalusers.currentRecord.username && props.portalusers.currentRecord.password && props.portalusers.currentRecord.confirmPassword) {
                      if (emailValidate(props.portalusers.currentRecord.username)) {
                        fncCheckUsername(props.portalusers.currentRecord.username, (ok) =>{
                          if (ok) {
                            if (props.portalusers.currentRecord.password === props.portalusers.currentRecord.confirmPassword) {
                              fncSave();
                            } else {
                              props.SetFormMessage(t("PortalMessages.Error.PasswordNotMatch"));
                            }
                          } else {
                            props.SetFormMessage(t("PortalMessages.Error.EmailExist"));
                          }
                        });
                      } else {
                        props.SetFormMessage(t("PortalMessages.Error.MailNotValid"));
                      }
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                    }
                  }}
                  className="tp_btn"
                />
              </Modal.Actions>
            </>
          ) : (
            <>
              <Modal.Header>{t("PortalUsers.ChangePassword")}</Modal.Header>
              <Modal.Content>
              {props.portalusers.formMessage !== null ? (
              <Message error content={props.portalusers.formMessage} />
            ) : null}
                <Form onChange={() => props.SetFormMessage(null)}>
                  <div className="tp_form_flex">
                    <Form.Input
                      label={t("PortalUsers.Password")}
                      type={"password"}
                      required
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            password: d.value,
                          })
                        )
                      }
                    />
                    <Form.Input
                      label={t("PortalUsers.ConfirmPassword")}
                      type={"password"}
                      required
                      onChange={(e, d) =>
                        props.SetCurrentRecord(
                          Object.assign(props.portalusers.currentRecord, {
                            confirmPassword: d.value,
                          })
                        )
                      }
                    />
                  </div>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={t("Button.Cancel")}
                  onClick={() => {
                    props.SetModal(
                      Object.assign(props.portalusers.modal, {
                        open: false,
                        type: "",
                      })
                    );
                    props.SetFormMessage(null);
                  }}
                />
                <Button
                  content={t("Button.Save")}
                onClick={() => {
                  if (props.portalusers.currentRecord.password && props.portalusers.currentRecord.confirmPassword) {
                    if (props.portalusers.currentRecord.password === props.portalusers.currentRecord.confirmPassword) {
                      fncChangePassword()
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.PasswordNotMatch"));
                    }
                  } else {
                    props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                  }
                }}
                  className="tp_btn"
                />
              </Modal.Actions>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

// ----------------------------------------------------------------

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, portalusers: state.portalusers };
}
const mapDispatchToProps = (dispatch) => {
  return {
    SetUsername: (value) => dispatch(SetUsername(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetPortalMessage: (value) => dispatch(SetPortalMessage(value)),

    SetLoginFormError: (value) => dispatch(SetLoginFormError(value)),

    SetAllRecords: (value) => dispatch(SetAllRecords(value)),
    SetCurrentRecord: (value) => dispatch(SetCurrentRecord(value)),
    SetModal: (value) => dispatch(SetModal(value)),
    SetFilter: (value) => dispatch(SetFilter(value)),
    SetColumnActive: (value) => dispatch(SetColumnActive(value)),
    SetColumnOrder: (value) => dispatch(SetColumnOrder(value)),
    SetCurrentPage: (value) => dispatch(SetCurrentPage(value)),
    SetTotalPages: (value) => dispatch(SetTotalPages(value)),
    SetSummary: (value) => dispatch(SetSummary(value)),
    SetFormMessage: (value) => dispatch(SetFormMessage(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PortalUsers);
