import Cookies from "universal-cookie";
import { Check as API_Check } from "../api/authentication";

const cookies = new Cookies(null, { path: "/" });
var authentication_header = [];

export default function ValidateSession(navigation, redirectToLogin) {
  return new Promise((ok, reject) => {
    let mycookie = cookies.get("tp-session");

    if (mycookie !== undefined && mycookie !== null) {
      authentication_header = atob(mycookie).split(":");
      API_Check(authentication_header[0], authentication_header[1])
        .then(() => {
          ok({
            loading: false,
            username: authentication_header[0],
            token: authentication_header[1],
          });
        })
        .catch(() => {
          DeleteSession(navigation, redirectToLogin);
          reject({
            expired: true,
          });
        });
    } else {
      DeleteSession(navigation, redirectToLogin);
      reject({
        expired: false,
      });
    }
  });
}

export function CreateSession(navigation, expiration, authentication_header) {
  DeleteSession(navigation, false);

  cookies.set("tp-session", btoa(authentication_header), {
    expires: expiration,
    path: "/",
  });
}

export function DeleteSession(navigation, redirectToLogin) {
  cookies.remove("tp-session");
  if (redirectToLogin === true) {
    navigation("/Login");
  }
}
