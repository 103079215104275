export const userRole = [
  {
    key: "Admin",
    text: "Admin",
    value: 1,
  },
  {
    key: "SubAdmin",
    text: "SubAdmin",
    value: 2,
  },
];
