import "./style.scss";
import React from "react";
import { Button, Form, Dropdown, Message } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { languageOptions } from "../../lib/language-options";
import { useTranslation } from "react-i18next";

// Cookie
import ValidateSession, { CreateSession } from "../session";

// API
import { Login as API_Login } from "../../api/authentication";
import { Reset as API_ResetPassword } from "../../api/authentication";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Session
import { connect } from "react-redux";
import { SetLang, SetToken, SetUsername } from "../../redux/global/actions";
import {
  SetLoginFormError,
  SetViewForgotPassword,
} from "../../redux/login/actions";





// ----------------------------------------------------------
function Login(props) {
  const grecaptchaObject = window.grecaptcha;
  var username = "";
  var password = "";
  var captcha = "";

  const navigation = useNavigate();
  const { t } = useTranslation();

  ValidateSession(navigation,false).then(() =>navigation("/")).catch(()=>{});

  // API error
  const fncAPIerror = (error) => {
    if (error.response === undefined || null) {
      props.SetLoginFormError({
        errorStatus: true,
        content: t("PortalMessages.Error.Server"),
      });
    } else if (error.response.status === 401) {
      props.SetLoginFormError({
        errorStatus: true,
        content: t("PortalMessages.Error.LoginError"),
      });
    } else {
      props.SetLoginFormError({
        errorStatus: true,
        content: t("PortalMessages.Error.Server"),
      });
    }
  }
  // ---------

  // Clean Messages
  const fncCleanPortalMessages = () => {
    props.SetLoginFormError({
      errorStatus: false,
      content: "",
    });
  }
  // ------

  // Login
  const fncAPILogin = (username, password) => {
    API_Login(username, password, captcha)
    .then((body) => {
      props.SetToken(body.data.token);
      props.SetUsername(username);
      CreateSession(
        navigation,
        new Date(body.data.expiration * 1000),
        username + ":" + body.data.token
      );
      navigation("/");
      fncCleanPortalMessages();
      
    })
    .catch((error) => {
      fncAPIerror(error);
      grecaptchaObject.reset();
    })
  }

  // Reset Password
  const fncAPIResetPassword = () => {
    API_ResetPassword(username)
    .catch((error) => {
      fncAPIerror(error);
    });
  }
  // ------

  return (
    <>
      <header id="tp_login_hr">
        <img
          src={"../../logo.svg"}
          id="tp_logo_img"
          alt="teams_power_logo"
        ></img>
        <Dropdown
          options={languageOptions}
          selection
          defaultValue={i18n.language}
          onChange={(e, d) => {
            i18n.changeLanguage(d.value);
            props.SetLang(d.value);
          }}
        />
      </header>
      <main id="tp_login_mn">
        <div>
          <div id="tp_login_form_ctr">
            <Form size="large" error={props.login.formError.errorStatus}>
              {props.login.viewForgotPassword === false ? (
                <>
                  <h1>{t("Login.Login")}</h1>
                  <Form.Field>
                  {
                      !props.login.formError.content ?
                      null
                      :
                      props.login.formError.errorStatus ?
                      <Message
                        error
                        content={props.login.formError.content}
                        size="mini"
                      /> 
                      : 
                      <Message
                        info
                        content={props.login.formError.content}
                        size="mini"
                      />
                    }
                    <Form.Input
                      type="email"
                      label={t("Login.Email")}
                      id="email_value"
                      onChange={()=>fncCleanPortalMessages()}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      type="password"
                      label={t("Login.Password")}
                      id="password_value"
                      onChange={()=>fncCleanPortalMessages()}
                    />
                  </Form.Field>
                  <div id="tp_recaptcha">
                    <ReCAPTCHA
                      grecaptcha={grecaptchaObject}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={(value) => captcha=value}
                    />
                  </div>
                  <div id="tp_form_btn_ctr">
                    <p onClick={() => {props.SetViewForgotPassword(true);fncCleanPortalMessages();}}>
                      {t("Login.ForgotPassword")}?
                    </p>
                    <Button
                      type="submit"
                      className="tp_btn"
                      onClick={() => {
                        username = document.querySelector("#email_value").value.trim();
                        password = document.querySelector("#password_value").value.trim();
                        if (username && password && captcha) {
                          fncAPILogin(username, password);
                          props.SetLoginFormError({
                            errorStatus: false,
                            content: t("PortalMessages.Loading"),
                          });
                        } else {
                          grecaptchaObject.reset();
                          props.SetLoginFormError({
                            errorStatus: true,
                            content: username && password ? t("PortalMessages.Error.Captcha") : t("PortalMessages.Error.LoginEmpty"),
                          });
                        }
                      }}
                    >
                      {t("Button.SignIn")}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <h1>{t("Login.ForgotPassword")}</h1>
                  <Form.Field>
                  {
                      !props.login.formError.content ?
                      null
                      :
                      props.login.formError.errorStatus ?
                      <Message
                        error
                        content={props.login.formError.content}
                        size="mini"
                      /> 
                      : 
                      <Message
                        info
                        content={props.login.formError.content}
                        size="mini"
                      />
                    }
                    <Form.Input
                      type="text"
                      label={t("Login.Email")}
                      id="email_reset_value"
                      onChange={()=>fncCleanPortalMessages()}
                    />
                  </Form.Field>
                  <div id="tp_form_btn_ctr">
                    <div>
                      <p onClick={() => {props.SetViewForgotPassword(false);fncCleanPortalMessages();}}>
                        {t("Login.GoBack")}
                      </p>
                    </div>
                    <Button
                      type="submit"
                      className="tp_btn"
                      onClick={() => {
                        username = document.querySelector("#email_reset_value").value.trim();
                        if (username) {
                          fncAPIResetPassword();
                          props.SetLoginFormError({
                            errorStatus: false,
                            content: t("PortalMessages.Error.ForgotPasswordDone"),
                          });
                        } else {
                          props.SetLoginFormError({
                            errorStatus: true,
                            content: t("PortalMessages.Error.MailNotValid"),
                          });
                        }
                      }}
                    >
                      {t("Button.Confirm")}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
        <img id="tp_bg_img" src="../../bg.svg" alt="" />
      </main>
    </>
  );
}
// ----------------------------------------------------------------

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, login: state.login };
}
const mapDispatchToProps = (dispatch) => {
  return {
    SetLang: (value) => dispatch(SetLang(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetUsername: (value) => dispatch(SetUsername(value)),

    SetLoginFormError: (value) => dispatch(SetLoginFormError(value)),
    SetViewForgotPassword: (value) => dispatch(SetViewForgotPassword(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
