import axios from "axios";

// Summary
export async function Summary(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/licenses/summary", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

// Page
export async function Page(
  username,
  token,
  page,
  license_mode,
  filter,
  column,
  column_order
) {
  return axios.get(
    process.env.REACT_APP_API_URI +
      "/licenses/pages?page=" +
      page +
      "&license_mode=" +
      license_mode +
      "&filter=" +
      filter +
      "&sort_column=" +
      column +
      "&sort_order=" +
      column_order,
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

//
export async function PageCount(username, token, filter, license_mode) {
  return axios.get(
    process.env.REACT_APP_API_URI +
      "/licenses/pages/count?filter=" +
      filter +
      "&license_mode=" +
      license_mode,
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

//
export async function License(username, token, api_key) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/licenses/license",
    {
      api_key: api_key,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

//
export async function Save(username, token, api_key, email, microsoft_email) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/licenses/save",
    {
      api_key: api_key,
      email: email,
      microsoft_email: microsoft_email,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

//
export async function Clear(username, token, api_key) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/licenses/clear",
    {
      api_key: api_key,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function Export(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/licenses/export", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}