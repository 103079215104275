export const SET_LOADING = "GLOBAL.SET_LOADING";
export function SetLoading(value) {
  return { type: SET_LOADING, data: value };
}

export const SET_LANG = "GLOBAL.SET_LANG";
export function SetLang(value) {
  return { type: SET_LANG, data: value };
}

export const SET_SIDEBAR_VISIBLE = "GLOBAL.SET_SIDEBAR_VISIBLE";
export function SetSideBarVisibile(value) {
  return { type: SET_SIDEBAR_VISIBLE, data: value };
}
export const SET_SIDEBAR_CONTENT = "GLOBAL.SET_SIDEBAR_CONTENT";
export function SetSideBarContent(value) {
  return { type: SET_SIDEBAR_CONTENT, data: value };
}

export const SET_TOKEN = "GLOBAL.SET_TOKEN";
export function SetToken(value) {
  return { type: SET_TOKEN, data: value };
}
export const SET_USERNAME = "GLOBAL.SET_USERNAME";
export function SetUsername(value) {
  return { type: SET_USERNAME, data: value };
}

export const SET_PROFILE = "GLOBAL.SET_PROFILE";
export function SetProfile(value) {
  return { type: SET_PROFILE, data: value };
}

export const SET_NOTIFICATIONS = "GLOBAL.SET_NOTIFICATIONS";
export function SetNotifications(value) {
  return { type: SET_NOTIFICATIONS, data: value };
}

export const SET_PORTAL_MESSAGE = "GLOBAL.SET_PORTAL_MESSAGE";
export function SetPortalMessage(value) {
  return { type: SET_PORTAL_MESSAGE, data: value };
}
