import {
  SET_ALL_RECORDS,
  SET_CURRENT_RECORD,
  SET_SUMMARY,
  SET_MODAL,
  SET_FILTER,
  SET_COLUMN_ACTIVE,
  SET_COLUMN_ORDER,
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGES,
  SET_FORM_MESSAGE
} from "./actions";

const STATE = {
  allRecords: null,
  currentRecord: {
    username: null,
    name: null,
    surname: null,
    password: null,
    confirmPassword: null,
    lang: "en",
    permissions: 2,
    notifications: false,
  },
  modal: {
    open: false,
    type: "",
  },

  summary: { admin_count: null, subadmin_count: null },

  filter: "",
  columnActive: "",
  columnOrder: "",

  currentPage: 1,
  totalPages: "",
  formMessage: null,
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_ALL_RECORDS:
      return Object.assign({}, state, { allRecords: actMsg.data });

    case SET_CURRENT_RECORD:
      return Object.assign({}, state, { currentRecord: actMsg.data });

    case SET_SUMMARY:
      return Object.assign({}, state, { summary: actMsg.data });

    case SET_MODAL:
      return Object.assign({}, state, { modal: actMsg.data });

    case SET_FILTER:
      return Object.assign({}, state, { filter: actMsg.data });

    case SET_COLUMN_ACTIVE:
      return Object.assign({}, state, { columnActive: actMsg.data });

    case SET_COLUMN_ORDER:
      return Object.assign({}, state, { columnOrder: actMsg.data });

    case SET_CURRENT_PAGE:
      return Object.assign({}, state, { currentPage: actMsg.data });

    case SET_TOTAL_PAGES:
      return Object.assign({}, state, { totalPages: actMsg.data });

    case SET_FORM_MESSAGE:
      return Object.assign({}, state, { formMessage: actMsg.data });

    default:
      return state;
  }
}
