import axios from "axios";

export async function Summary(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/tenants/summary", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

export async function Tenant(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/tenants/tenant", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

export async function SaveTenant(
  username,
  token,
  microsoft_tenant_id,
  microsoft_client_id,
  microsoft_client_secret
) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/tenants/save",
    {
      microsoft_tenant_id: microsoft_tenant_id,
      microsoft_client_id: microsoft_client_id,
      microsoft_client_secret: microsoft_client_secret,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function Balanced(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/tenants/balanced-rooms", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

export async function SaveBalanced(username, token, rooms) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/tenants/balanced-rooms",
    {
      rooms: rooms,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}
