import "./dashboard.scss";
import { Button } from "semantic-ui-react";
import Skeleton from "react-skeleton-loader";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom Functions
import { expirationSession } from "../../lib/expirationSession";

// API
import {
  Summary as API_PortalUsers_Summary,
  User as API_User,
} from "../../api/portalusers";
import { Summary as API_Licenses_Summary } from "../../api/licenses";
import { Summary as API_Tenant_Summary } from "../../api/tenant";

// Components
import Loading from "../../components/shared/LoadingScreen";

// Session
import { connect } from "react-redux";
import { SetPortalMessage  } from "../../redux/global/actions";
import {
  SetTenantInfo,
  SetLicensesInfo,
  SetPortalUsersInfo,
  SetUserInfo,
} from "../../redux/dashboard/actions";

// -----------------------------------------------------

function Dashboard(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  // Normalized Date
  const fncNormalizedDate = (date) => {
    var new_date = new Date(date * 1000);
    return (
      new_date.getDate().toString().padStart(2, "0") +
      "/" +
      (new_date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new_date.getFullYear().toString()
    );
  };
  // ------

  // API
  const fncUpdateGrid = () => {
    API_PortalUsers_Summary(props.global.username, props.global.token)
      .then((body) => props.SetPortalUsersInfo(body.data))
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (500).",
          });
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
          });
        }
      });

    API_Licenses_Summary(props.global.username, props.global.token)
      .then((body) => props.SetLicensesInfo(body.data))
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (500).",
          });
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
          });
        }
      });

      API_Tenant_Summary(props.global.username, props.global.token)
      .then((body) => props.SetTenantInfo(body.data))
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (500).",
          });
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
          });
        }
      });

    API_User(props.global.username, props.global.token, props.global.username)
      .then((body) => props.SetUserInfo(body.data))
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (500).",
          });
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetPortalMessage({
            error: true,
            content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
          });
        }
      });
  }

  useEffect(() => {
    fncUpdateGrid();
  }, []);
  // ------

  if (props.dashboard === undefined || props.dashboard === null) {
    return <Loading />;
  } else {
    return (
      <div id="tp_dashboard_ctr">
        <div id="tp_card_ctr">
          <div className="tp_card" id="tp_card_colored">
            <div className="tp_card_hr_welcome">
              <p>{t("Dashboard.Welcome")},</p>
              <p>
                {props.dashboard.user.name === null &&
                props.dashboard.user.surname === null ? (
                  <Skeleton color="var(--tp_skeleton_color)" />
                ) : (
                  props.dashboard.user.name + " " + props.dashboard.user.surname
                )}
              </p>
              <p>
              {props.dashboard.user.organization === null ? (
                <Skeleton color="var(--tp_skeleton_color)" />
                  ) : props.dashboard.user.organization   
                  }
              </p>
            </div>
          </div>
          <div className="tp_card tp_card_hover">
            <div className="tp_card_hr">
              <div>
                <p>{t("Dashboard.TenantCard.Tenant")}</p>
                <Button
                    icon="arrow right"
                    circular
                    onClick={() => navigation("/Tenant")}
                    size="large"
                  />
              </div>
              <div className="tp_card_description">
                <p>{t("Dashboard.TenantCard.Description")}</p>
              </div>
            </div>

            <div className="tp_card_mn">
              <div className="tp_card_info">
                <p>{t("Dashboard.TenantCard.BalancedMode")}</p>
                {props.dashboard.tenant.balanced_licenses === null ? (
                  <Skeleton
                    color="var(--tp_skeleton_color)"
                    width="50px"
                    widthRandomness="0"
                  />
                ) : props.dashboard.tenant.balanced_licenses === true ? (
                  <p className="tp_card_status_active">{t("Status.Active")}</p>
                ) : (
                  <p className="tp_card_status_unactive">
                    {t("Status.UnActive")}
                  </p>
                )}
              </div>
              <div className="tp_card_info">
                <p>{t("Dashboard.TenantCard.DirectMode")}</p>
                {props.dashboard.tenant.direct_licenses === null ? (
                  <Skeleton
                    color="var(--tp_skeleton_color)"
                    width="50px"
                    widthRandomness="0"
                  />
                ) : props.dashboard.tenant.direct_licenses === true ? (
                  <p className="tp_card_status_active">{t("Status.Active")}</p>
                ) : (
                  <p className="tp_card_status_unactive">
                    {t("Status.UnActive")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="tp_card tp_card_hover" >
            <div className="tp_card_hr">
                <div>
                  <p>{t("Dashboard.LicenseCard.Licenses")}</p>
                  <Button
                    icon="arrow right"
                    circular
                    onClick={() => navigation("/Licenses")}
                    size="large"
                  />
                </div>
                <div className="tp_card_description">
                  <p>{t("Dashboard.LicenseCard.Description")}</p>
                </div>
            </div>
            <div className="tp_card_mn">
              <div className="tp_card_info">
                <p>{t("Dashboard.LicenseCard.FirstExpiration")}</p>
                <strong>
                  {props.dashboard.licenses.first_license_expiration ===
                  null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) :
                  props.dashboard.licenses.first_license_expiration > -1 ?
                  fncNormalizedDate(
                    props.dashboard.licenses.first_license_expiration
                  )
                  :
                  '-'
                  }
                </strong>
              </div>
              <div className="tp_card_info">
                <p>{t("Dashboard.LicenseCard.FreeLicense")}</p>
                <strong>
                  {props.dashboard.licenses.free_licenses_count === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) : (
                    props.dashboard.licenses.free_licenses_count
                  )}
                </strong>
              </div>
              <div className="tp_card_info">
                <p>{t("Dashboard.LicenseCard.BalancedLicense")}</p>
                <strong>
                  {props.dashboard.licenses.balanced_licenses_count === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) : (
                    props.dashboard.licenses.balanced_licenses_count
                  )}
                </strong>
              </div>
              <div className="tp_card_info">
                <p>{t("Dashboard.LicenseCard.DirectLicense")}</p>
                <strong>
                  {props.dashboard.licenses.direct_licenses_count === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) : (
                    props.dashboard.licenses.direct_licenses_count
                  )}
                </strong>
              </div>
            </div>
          </div>

          {props.dashboard.user.permissions === 1 ? (
            <div className="tp_card tp_card_hover" >
              <div className="tp_card_hr">
                <div>
                  <p>{t("Dashboard.PortalUsersCard.PortalUsers")}</p>
                  <Button
                    icon="arrow right"
                    circular
                    onClick={() => navigation("/PortalUsers")}
                    size="large"
                  />
                </div>
                <div className="tp_card_description">
                  <p>{t("Dashboard.PortalUsersCard.Description")}</p>
                </div>
              </div>
              <div className="tp_card_mn">
                <div className="tp_card_info">
                  <p>Admin</p>
                  <strong>
                    {props.dashboard.portalUser.admin_count === null ? (
                      <Skeleton
                        color="var(--tp_skeleton_color)"
                        width="50px"
                        widthRandomness="0"
                      />
                    ) : (
                      props.dashboard.portalUser.admin_count
                    )}
                  </strong>
                </div>
                <div className="tp_card_info">
                  <p>SubAdmin</p>
                  <strong>
                    {props.dashboard.portalUser.subadmin_count === null ? (
                      <Skeleton
                        color="var(--tp_skeleton_color)"
                        width="50px"
                        widthRandomness="0"
                      />
                    ) : (
                      props.dashboard.portalUser.subadmin_count
                    )}
                  </strong>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

// ----------------------------------------------------------------

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, dashboard: state.dashboard };
}
const mapDispatchToProps = (dispatch) => {
  return {
    SetPortalMessage: (value) => dispatch(SetPortalMessage(value)),

    SetTenantInfo: (value) => dispatch(SetTenantInfo(value)),
    SetLicensesInfo: (value) => dispatch(SetLicensesInfo(value)),
    SetPortalUsersInfo: (value) => dispatch(SetPortalUsersInfo(value)),
    SetUserInfo: (value) => dispatch(SetUserInfo(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
