import { combineReducers } from "redux";

// State
import GlobalReducer from "./global/state";
import LoginReducer from "./login/state";
import Dashboard from "./dashboard/state";
import TenantReducer from "./tenant/state";
import LicensesReducer from "./licenses/state";
import PortalUsers from "./portalusers/state";

const rootReducer = combineReducers({
  global: GlobalReducer,

  login: LoginReducer,
  dashboard: Dashboard,
  tenant: TenantReducer,
  licenses: LicensesReducer,
  portalusers: PortalUsers,
});

export default rootReducer;
