import { Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Session
import {
  SetLang,
  SetSideBarVisibile,
  SetSideBarContent,
  SetNotifications,
  SetPortalMessage,
} from "../../redux/global/actions";
import { connect } from "react-redux";

// ----------------------------------------------------------

function NavBar(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const fncPortalMessage = () => {
    if (props.global.portalMessage !== null) {
      setTimeout(() => {
        props.SetPortalMessage(null);
      }, 5000);
      if (props.global.portalMessage.error === true) {
        return (
          <div id="tp_portal_message">
            <Icon name="times" />
            <p>{props.global.portalMessage.content}</p>
          </div>
        );
      } else {
        return (
          <div id="tp_portal_message">
            <Icon name="checkmark" />
            <p>{props.global.portalMessage.content}</p>
          </div>
        );
      }
    }
  };

  return (
    <>
      {fncPortalMessage()}

      <header id="tp_navbar_ctr">
        <div id="tp_primary_hr">
          <img
            src={"../../logo.svg"}
            id="tp_logo_img"
            alt="teams_power_logo"
          ></img>
          <div id="tp_primary_hr_btn">
            <Button
              icon="info"
              size="mini"
              className="tp_btn_outline"
              circular
              onClick={() => {
                props.SetSideBarVisibile(true);
                props.SetSideBarContent("info");
              }}
            />
            <div id="tp_notifications_btn">
              <Button
                icon="bell"
                size="large"
                circular
                onClick={() => {
                  props.SetSideBarVisibile(true);
                  props.SetSideBarContent("notify");
                }}
              />
              {props.global.allNotifications === null ||
              props.global.allNotifications.notifications.length < 1 ? null : (
                <div id="tp_notifications_dot"></div>
              )}
            </div>
            <Button
              icon="user"
              circular
              onClick={() => {
                props.SetSideBarVisibile(true);
                props.SetSideBarContent("profile");
              }}
              size="large"
            />
          </div>
        </div>
        <div id="tp_secondary_hr">
          <div id="tp_breadcrumb">
            {props.current_view !== "Dashboard" ? (
              <Button
                icon="arrow left"
                size="large"
                circular
                onClick={() => navigation("/")}
              />
            ) : null}
            <h1>
              {props.current_view === "PortalUsers"
                ? t("NavBar.PortalUsers")
                : props.current_view === "Tenant"
                ? t("NavBar.Tenant")
                : props.current_view === "Licenses"
                ? t("NavBar.Licenses")
                : props.current_view === "Dashboard"
                ? t("NavBar.Dashboard")
                : null}
            </h1>
          </div>
        </div>
      </header>
    </>
  );
}

// ----------------------------------------------------------
// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global };
}

const mapDispatchToProps = (dispatch) => {
  return {
    SetPortalMessage: (value) => dispatch(SetPortalMessage(value)),

    SetLang: (value) => dispatch(SetLang(value)),
    SetSideBarVisibile: (value) => dispatch(SetSideBarVisibile(value)),
    SetSideBarContent: (value) => dispatch(SetSideBarContent(value)),
    SetNotifications: (value) => dispatch(SetNotifications(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
