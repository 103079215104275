import React, { useEffect } from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Cookies
import ValidateSession from "./views/session";

// SideBar
import {
  SetSideBarVisibile,
  SetLoading,
  SetToken,
  SetUsername,
} from "./redux/global/actions";
import { SetLoginFormError } from "./redux/login/actions";

// Component
import NavBar from "./components/shared/NavBar";
import SideBar from "./components/shared/SideBar";
import Loading from "./components/shared/LoadingScreen";

function Template(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    ValidateSession(navigation, true)
      .then((body) => {
        props.SetLoading(body.loading);
        props.SetUsername(body.username);
        props.SetToken(body.token);
      })
      .catch((error) => {
        if (error.expired) {
          props.SetLoginFormError({
            errorStatus: true,
            content: t("PortalMessages.Error.SessionExpired"),
          });
        }
      });
  }, [props.current_view]);

  if (props.global.loading) {
    return <Loading />;
  } else {
    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="right"
          onHide={() => props.SetSideBarVisibile(false)}
          vertical
          visible={props.global.sideBarVisible}
          width="wide"
        >
          <SideBar content={props.global.sideBarContent} />
        </Sidebar>

        <Sidebar.Pusher dimmed={props.global.sideBarVisible}>
          <header id="tp_navbar_ctr">
            <NavBar current_view={props.current_view} />
          </header>
          <main id="tp_main_ctr">{props.main_component}</main>
          {props.current_view === "Dashboard" ||
          props.current_view === "Tenant" ? (
            <div id="tp_img_footer"></div>
          ) : null}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, login: state.login };
}

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoading: (value) => dispatch(SetLoading(value)),
    SetSideBarVisibile: (value) => dispatch(SetSideBarVisibile(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetUsername: (value) => dispatch(SetUsername(value)),

    SetLoginFormError: (value) => dispatch(SetLoginFormError(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Template);
