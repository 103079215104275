import axios from "axios";

export async function Login(username, password, captcha) {
  return axios.post(process.env.REACT_APP_API_URI + "/authentication", {
    username: username,
    password: password,
    captcha: captcha,
  });
}

export function Logout(username, token) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/authentication/logout",
    {},
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export function Reset(username) {
  return axios.post(process.env.REACT_APP_API_URI + "/authentication/reset", {
    username: username,
  });
}

export function Check(username, token) {
  return axios.post(process.env.REACT_APP_API_URI + "/authentication/check", {},
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}
