export const SET_TENANT_DETAILS = "TENANT.SET_TENANT_DETAILS";
export function SetTenantDetails(value) {
  return { type: SET_TENANT_DETAILS, data: value };
}

export const SET_BALANCED_DETAILS = "TENANT.SET_BALANCED_DETAILS";
export function SetBalancedDetails(value) {
  return { type: SET_BALANCED_DETAILS, data: value };
}

export const SET_MODE_DETAILS = "TENANT.SET_MODE_DETAILS";
export function SetModeDetails(value) {
  return { type: SET_MODE_DETAILS, data: value };
}

export const SET_MODAL = "TENANT.SET_MODAL";
export function SetModal(value) {
  return { type: SET_MODAL, data: value };
}

export const SET_ZIMLET_TOKEN = "TENANT.SET_ZIMLET_TOKEN";
export function SetZimletToken(value) {
  return { type: SET_ZIMLET_TOKEN, data: value };
}

export const SET_FORM_MESSAGE = "TENANT.SET_FORM_MESSAGE";
export function SetFormMessage(value) {
  return { type: SET_FORM_MESSAGE, data: value };
}