import {
  SET_TENANT_INFO,
  SET_LICENSES_INFO,
  SET_PORTAL_USERS_INFO,
  SET_USER_INFO,
} from "./actions";

const STATE = {
  tenant: {
    balanced_licenses: null,
    direct_licenses: null,
  },
  licenses: {
    balanced_licenses_count: null,
    direct_licenses_count: null,
    free_licenses_count:null,
    first_license_expiration: null,
  },
  portalUser: {
    admin_count: 0,
    subadmin_count: 0,
  },
  user: { name: null, surname: null, permissions: null, organization: null },
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_TENANT_INFO:
      return Object.assign({}, state, { tenant: actMsg.data });
    case SET_LICENSES_INFO:
      return Object.assign({}, state, { licenses: actMsg.data });
    case SET_PORTAL_USERS_INFO:
      return Object.assign({}, state, { portalUser: actMsg.data });
    case SET_USER_INFO:
      return Object.assign({}, state, { user: actMsg.data });

    default:
      return state;
  }
}
