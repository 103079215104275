import {
  SET_LOADING,
  SET_SIDEBAR_VISIBLE,
  SET_SIDEBAR_CONTENT,
  SET_LANG,
  SET_TOKEN,
  SET_USERNAME,
  SET_PROFILE,
  SET_NOTIFICATIONS,
  SET_PORTAL_MESSAGE,
} from "./actions";

const STATE = {
  loading: true,
  sideBarVisible: false,
  sideBarContent: "",
  token: "",
  username: "",
  profile: {
    name: null,
    surname: null,
    username: null,
    permissions: null,
    organization: null,
    lang: "en",
  },
  allNotifications: null,
  portalMessage: null,
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_LOADING:
      return Object.assign({}, state, { loading: actMsg.data });

    case SET_LANG:
      return Object.assign({}, state, { lang: actMsg.data });

    case SET_SIDEBAR_VISIBLE:
      return Object.assign({}, state, { sideBarVisible: actMsg.data });

    case SET_SIDEBAR_CONTENT:
      return Object.assign({}, state, { sideBarContent: actMsg.data });

    case SET_TOKEN:
      return Object.assign({}, state, { token: actMsg.data });

    case SET_USERNAME:
      return Object.assign({}, state, { username: actMsg.data });

    case SET_PROFILE:
      return Object.assign({}, state, { profile: actMsg.data });

    case SET_NOTIFICATIONS:
      return Object.assign({}, state, { allNotifications: actMsg.data });

    case SET_PORTAL_MESSAGE:
      return Object.assign({}, state, { portalMessage: actMsg.data });

    default:
      return state;
  }
}
