import { expirationSession } from "../../lib/expirationSession";
import {
  SET_TENANT_DETAILS,
  SET_BALANCED_DETAILS,
  SET_MODE_DETAILS,
  SET_MODAL,
  SET_ZIMLET_TOKEN,
  SET_FORM_MESSAGE
} from "./actions";

const STATE = {
  tenantDetails: {
    domain: null,
    microsoft_tenant_id: "",
    microsoft_client_id: "",
    microsoft_client_secret: "",
    expiration_key: ""
  },

  balancedDetails: { rooms: [] },

  modeDetails: {
    balanced_licenses: null,
    direct_licenses: null,
    balanced_rooms_count: null,
  },

  modal: {
    open: false,
    type: "",
  },


  zimletKeys: {
    admin_zimlet_customer_token: null,
    admin_zimlet_customer_secret: null
  },
  formMessage: null,
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_TENANT_DETAILS:
      return Object.assign({}, state, { tenantDetails: actMsg.data });

    case SET_BALANCED_DETAILS:
      return Object.assign({}, state, { balancedDetails: actMsg.data });

    case SET_MODE_DETAILS:
      return Object.assign({}, state, { modeDetails: actMsg.data });

    case SET_MODAL:
      return Object.assign({}, state, { modal: actMsg.data });

    case SET_ZIMLET_TOKEN:
      return Object.assign({}, state, { zimletKeys: actMsg.data });

    case SET_FORM_MESSAGE:
      return Object.assign({}, state, { formMessage: actMsg.data });

    default:
      return state;
  }
}
