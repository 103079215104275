import "./tenant.scss";
import { Modal, Button, Form, Message, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-skeleton-loader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


// Custom Functions
import { emailValidate } from "../../lib/emailValidation";
import { expirationSession } from "../../lib/expirationSession";

// API
import {
  Summary as API_Summary,
  Tenant as API_Tenant,
  SaveTenant as API_SaveTenant,
  Balanced as API_Balanced,
  SaveBalanced as API_SaveBalanced,
} from "../../api/tenant";
import { ZimletToken as API_ZimletToken } from "../../api/zimlet";

// Components
import Loading from "../../components/shared/LoadingScreen";

// Redux
import { connect } from "react-redux";
import { SetUsername, SetToken, SetPortalMessage } from "../../redux/global/actions";
import {
  SetTenantDetails,
  SetBalancedDetails,
  SetModeDetails,
  SetModal,
  SetZimletToken,
  SetFormMessage
} from "../../redux/tenant/actions";

// -----------------------------------------------------

function Tenant(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  // API error
  const fncAPIGetError = (error) => {
    if (error.response === undefined || null) {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (500).",
      });
    } else if (error.response.status === 401) {
      expirationSession(props, t, navigation);
    } else {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
      });
    }
  };
  // ---------

  // Email Rooms
  var fncEmailRooms = () => {
    let rows = [];
    props.tenant.balancedDetails.rooms.forEach((element, index) => {
      rows.push(
        <div className="tp_email_rooms" key={index}>
          <p>{element}</p>
          <Button
            icon="trash"
            circular
            onClick={() => {
              if (props.tenant.balancedDetails.rooms.length > 1) {
                props.SetBalancedDetails({
                  rooms: [].concat(
                    props.tenant.balancedDetails.rooms.slice(0, index),
                    props.tenant.balancedDetails.rooms.slice(index + 1)
                  ),
                });
                props.SetFormMessage(null);
              } else {
                props.SetFormMessage(t("PortalMessages.Error.EmailRooms"));
              }
            }}
          />
        </div>
      );
    });
    return rows;
  };
  // ---------


  // -------------------------------

  const fncUpdateGrid = () => {
    API_Tenant(props.global.username, props.global.token)
      .then((body) => {
        props.SetTenantDetails(body.data);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    API_Summary(props.global.username, props.global.token)
      .then((body) => {
        props.SetModeDetails(body.data);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    API_Balanced(props.global.username, props.global.token)
      .then((body) => {
        props.SetBalancedDetails(body.data);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

  API_ZimletToken(props.global.username, props.global.token)
    .then((body) => {
      props.SetZimletToken(body.data);
    })
    .catch((error) => {
      fncAPIGetError(error);
    });
  }




  const fncSaveTenant = () => {
    API_SaveTenant(
      props.global.username,
      props.global.token,
      props.tenant.tenantDetails.microsoft_tenant_id,
      props.tenant.tenantDetails.microsoft_client_id,
      props.tenant.tenantDetails.microsoft_client_secret
    )
      .then(() => {
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Saved"),
        });
        fncUpdateGrid();
        props.SetFormMessage(null);
        props.SetModal({ open: false, type: "" });
      })
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetFormMessage(t("PortalMessages.Error.KeysNotValid"));
        }
      });
  };

  const fncSaveBalanced = () => {
    API_SaveBalanced(
      props.global.username,
      props.global.token,
      props.tenant.balancedDetails.rooms
    )
      .then(() => {
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Saved"),
        });
        fncUpdateGrid();
        props.SetFormMessage(null);
        props.SetModal({ open: false, type: "" });
      })
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetFormMessage(t("PortalMessages.Error."));
        }
      });
  };
  // ---------

  useEffect(() => {
    fncUpdateGrid();
  }, []);

  // -------------------------------------------------------------------

  if (props.tenant === undefined || props.tenant === null) {
    return <Loading />;
  } else {
    return (
      <>
      <div id="tp_card_ctr">
          <div className="tp_card" id="tp_card_colored">
              <div className="tp_card_hr">
                <div>
                  <p>{t("Tenant.TenantCard.TenantDetail")}</p>
                  <Button
                    icon='pencil'
                    circular
                    onClick={() => {
                      props.SetModal({ open: true, type: 1 });
                    }}
                    size="medium"
                  />
                </div>
                <div className="tp_card_description_colored">
                  <p>{t("Tenant.TenantCard.Description")}</p>
                </div>
              </div>
              <div className="tp_card_mn" id="tp_card_mn_colored">
                <div className="tp_card_info tp_card_info_colored">
                  <p>{t("Tenant.TenantCard.Domain")}</p>
                  <span>
                    {props.tenant.tenantDetails.domain === null ? (
                      <Skeleton
                        color="var(--tp_skeleton_color)"
                        width="50px"
                        widthRandomness="0"
                      />
                    ) : (
                      props.tenant.tenantDetails.domain
                    )}
                  </span>
                </div>
                <div className="tp_card_info tp_card_info_colored">
                  <p>{t("Tenant.TenantCard.TenantId")}</p>
                  <span>**********</span>
                </div>
                <div className="tp_card_info tp_card_info_colored">
                  <p>{t("Tenant.TenantCard.ClientId")}</p>
                  <span>**********</span>
                </div>
                <div className="tp_card_info tp_card_info_colored">
                  <p>{t("Tenant.TenantCard.ClientSecret")}</p>
                  <span>**********</span>
                </div>
              </div>
            </div>
            
            <div className="tp_card">
              <div className="tp_card_hr">
                <div>
                  <p>{t("Tenant.ModeStatusCard.ModeStatus")}</p>
                  {
                    props.tenant.modeDetails.balanced_licenses === true ? (
                      <Button
                      icon='pencil'
                      circular
                      onClick={() => {
                        props.SetModal({ open: true, type: 2 });
                      }}
                      size="medium"
                    />
                    ) : null
                  }
                </div>
                <div className="tp_card_description">
                  <p>{t("Tenant.ModeStatusCard.Description")}</p>
                </div>
              </div>
              <div className="tp_card_mn">
                <div className="tp_card_info">
                  <p>{t("Tenant.ModeStatusCard.Balanced")}</p>
                  {props.tenant.modeDetails.balanced_licenses === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) : props.tenant.modeDetails.balanced_licenses === true ? (
                    <p className="tp_card_status_active">
                      {t("Status.Active")}
                    </p>
                  ) : (
                    <p className="tp_card_status_unactive">
                      {t("Status.UnActive")}
                    </p>
                  )}
                </div>
                <div className="tp_card_info">
                  <p>{t("Tenant.ModeStatusCard.Direct")}</p>
                  {props.tenant.modeDetails.direct_licenses === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) : props.tenant.modeDetails.direct_licenses === true ? (
                    <p className="tp_card_status_active">
                      {t("Status.Active")}
                    </p>
                  ) : (
                    <p className="tp_card_status_unactive">
                      {t("Status.UnActive")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="tp_card">
              <div className="tp_card_hr">
                <div>
                <p>{t("Tenant.ZimetCard.Zimlet")}</p>
                </div>
                <div className="tp_card_description">
                  <p>{t("Tenant.ZimetCard.Description")}</p>
                </div>
              </div>
              <div className="tp_card_mn">
                <div className="tp_card_info">
                  <p>{t("Tenant.ZimetCard.Token")}</p>
                  {props.tenant.zimletKeys.admin_zimlet_customer_token === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) :
                  <Popup
                  content={t("Input.Copy")}
                  on="click"
                  pinned
                  trigger={
                    <span style={{textDecoration:"underline",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(props.tenant.zimletKeys.admin_zimlet_customer_token)}}>
                    {props.tenant.zimletKeys.admin_zimlet_customer_token.length > 20? props.tenant.zimletKeys.admin_zimlet_customer_token.slice(0,20) + '...' : props.tenant.zimletKeys.admin_zimlet_customer_token}
                  </span>  
                  }
                />
                  }
                </div>
                <div className="tp_card_info">
                  <p>{t("Tenant.ZimetCard.Secret")}</p>
                  {props.tenant.zimletKeys.admin_zimlet_customer_secret === null ? (
                    <Skeleton
                      color="var(--tp_skeleton_color)"
                      width="50px"
                      widthRandomness="0"
                    />
                  ) :
                  <Popup
                  content={t("Input.Copy")}
                  on="click"
                  pinned
                  trigger={
                    <span style={{textDecoration:"underline",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(props.tenant.zimletKeys.admin_zimlet_customer_secret)}}>
                    {props.tenant.zimletKeys.admin_zimlet_customer_secret.length > 20? props.tenant.zimletKeys.admin_zimlet_customer_secret.slice(0,20) + '...' : props.tenant.zimletKeys.admin_zimlet_customer_secret}
                  </span> 
                  }
                />
                  }
                </div>
              </div>
            </div>
          </div>
          
        <Modal
          onClose={() =>
            props.SetModal(Object.assign(props.tenant.modal, { open: false }))
          }
          open={props.tenant.modal.open}
        >
          {props.tenant.modal.type === 1 ? (
            <>
              <Modal.Header>
                {t("Tenant.EditTenant")}
              </Modal.Header>
              <Modal.Content>
              {props.tenant.formMessage !== null ? (
              <Message error content={props.tenant.formMessage} />
            ) : null}
                <Form onChange={()=>SetFormMessage(null)}>
                  <div className="tp_disabled">
                    <strong>{t("Tenant.TenantCard.Domain")}</strong>
                    <p>{props.tenant.tenantDetails.domain}</p>
                  </div>
                  <Form.Input
                    label={t("Tenant.TenantCard.TenantId")}
                    defaultValue={
                      props.tenant.tenantDetails.microsoft_tenant_id
                    }
                    required
                    onChange={(e, d) =>
                      props.SetTenantDetails(
                        Object.assign(props.tenant.tenantDetails, {
                          microsoft_tenant_id: d.value,
                        })
                      )
                    }
                  />
                  <Form.Input
                    label={t("Tenant.TenantCard.ClientId")}
                    defaultValue={
                      props.tenant.tenantDetails.microsoft_client_id
                    }
                    required
                    onChange={(e, d) =>
                      props.SetTenantDetails(
                        Object.assign(props.tenant.tenantDetails, {
                          microsoft_client_id: d.value,
                        })
                      )
                    }
                  />
                  <Form.Input
                    label={t("Tenant.TenantCard.ClientSecret")}
                    defaultValue={
                      props.tenant.tenantDetails.microsoft_client_secret
                    }
                    required
                    onChange={(e, d) =>
                      props.SetTenantDetails(
                        Object.assign(props.tenant.tenantDetails, {
                          microsoft_client_secret: d.value,
                        })
                      )
                    }
                  />
                  
                  {/* 
                  EXPIRTATION KEY DATE
                  <Form.Input
                    type="date"
                    disabled={true}
                    placeholder=""
                    label={t("Tenant.TenantCard.KeyExpiration")}
                    defaultValue={
                      props.tenant.tenantDetails.expiration_key
                    }
                    required
                    onChange={(e, d) =>
                      props.SetTenantDetails(
                        Object.assign(props.tenant.tenantDetails, {
                          expiration_key: d.value,
                        })
                      )
                    }
                  /> */}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={t("Button.Cancel")}
                  onClick={() => {
                    props.SetModal({ open: false, type: "" });
                    props.SetFormMessage(null);
                  }}
                />
                <Button
                  content={t("Button.Save")}
                  className="tp_btn"
                  onClick={() => {
                    // props.tenant.tenantDetails expiration_key
                    if (
                      props.tenant.tenantDetails.microsoft_tenant_id &&
                      props.tenant.tenantDetails.microsoft_client_id &&
                      props.tenant.tenantDetails.microsoft_client_secret
                    ) {
                      fncSaveTenant();
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                    }
                  }}
                />
              </Modal.Actions>
            </>
          ) : (
            <>
              <Modal.Header>
                {t("Tenant.EditBalancedMode")}
              </Modal.Header>
              <Modal.Content>
              {props.tenant.formMessage !== null ? (
              <Message error content={props.tenant.formMessage} />
            ) : null}
                <Message info content={t("Tenant.Message")} />
                <Form onChange={()=>SetFormMessage(null)}>
                  <div id="tp_add_mail_rooms">
                    <Form.Input
                      label={ 'Microsoft Teams Account' + ' (' + props.tenant.modeDetails.balanced_rooms_count + ')'}
                      id="balanced_room_value"
                    />
                    <Form.Button
                      icon="plus"
                      circular
                      onClick={() => {
                        var room = document.querySelector("#balanced_room_value").value.trim();
                        if (room) {
                          if (emailValidate(room)) {
                            if (props.tenant.balancedDetails.rooms.indexOf(room) === -1) {
                              let copy =
                                props.tenant.balancedDetails.rooms.splice(0);
                              copy.push(room);
                              props.SetBalancedDetails({ rooms: copy });
                              props.SetFormMessage(null);
                              document.querySelector("#balanced_room_value").value = "";
                            } else {
                              props.SetFormMessage(t("PortalMessages.Error.EmailExist"));
                            }
                           
                          } else {
                            props.SetFormMessage(t("PortalMessages.Error.MailNotValid"));
                          }
                        } else {
                          props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                        }
                      }}
                    />
                  </div>
                  <div id="tp_email_rooms_ctr">{fncEmailRooms()}</div>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={t("Button.Cancel")}
                  onClick={() => {
                    props.SetModal({ open: false, type: "" });
                    props.SetFormMessage(null);
                  }}
                />
                <Button
                  content={t("Button.Save")}
                  className="tp_btn"
                  onClick={() => {
                    fncSaveBalanced();
                  }}
                />
              </Modal.Actions>
            </>
          )}
        </Modal>
      </>
    );
  }
}

// -----------------------------------------------------

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, tenant: state.tenant };
}

const mapDispatchToProps = (dispatch) => {
  return {
    SetUsername: (value) => dispatch(SetUsername(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetPortalMessage: (value) => dispatch(SetPortalMessage(value)),

    SetTenantDetails: (value) => dispatch(SetTenantDetails(value)),
    SetBalancedDetails: (value) => dispatch(SetBalancedDetails(value)),
    SetModeDetails: (value) => dispatch(SetModeDetails(value)),
    SetModal: (value) => dispatch(SetModal(value)),
    SetZimletToken: (value) => dispatch(SetZimletToken(value)),
    SetFormMessage: (value) => dispatch(SetFormMessage(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tenant);
