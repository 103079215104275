import { Button, Dropdown, Icon } from "semantic-ui-react";
import Skeleton from "react-skeleton-loader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { languageOptions } from "../../lib/language-options";

// Custom Functions
import { expirationSession } from "../../lib/expirationSession";

// Cookies
import { DeleteSession } from "../../views/session";

// API
import { Logout as API_Logout } from "../../api/authentication";
import { User as API_User } from "../../api/portalusers";
import { Notification as API_Notification } from "../../api/notifications";

// Session
import { connect } from "react-redux";
import {
  SetLoading,
  SetLang,
  SetSideBarContent,
  SetSideBarVisibile,
  SetUsername,
  SetToken,
  SetProfile,
  SetNotifications,
} from "../../redux/global/actions";

// ----------------------------------------------------------

function SideBar(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const fncNormalizedDate = (date) => {
    var new_date = new Date(date * 1000);
    return (
      new_date.getDate().toString().padStart(2, "0") +
      "/" +
      (new_date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new_date.getFullYear().toString() +
      " " +
      new_date.getHours().toString().padStart(2, "0") +
      ":" +
      new_date.getMinutes().toString().padStart(2, "0")
    );
  };

  const fncGetMessages = () => {
    let messages = [];
    props.global.allNotifications.notifications.forEach((element, index) => {
      messages.push(
        <div className="tp_sidebar_notify" key={index}>
          <h3>{element.title}</h3>
          {element.message.split("\n").map((row, index) => {
            return <p key={index}>{row}</p>;
          })}
          <p>{fncNormalizedDate(element.creation_date)}</p>
        </div>
      );
    });
    return messages;
  };

  // API
  const fncUpdateGrid = () => {
    API_User(props.global.username, props.global.token, props.global.username)
      .then((body) => props.SetProfile(body.data))
      .catch((error) => {        if (error.response === undefined || null) {
        props.SetPortalMessage({
          error: true,
          content: t("PortalMessages.Error.Server") + " (500).",
        });
      } else if (error.response.status === 401) {
        expirationSession(props, t, navigation);
      } else {
        props.SetPortalMessage({
          error: true,
          content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
        });
      }});

    API_Notification(props.global.username, props.global.token)
      .then((body) => props.SetNotifications(body.data))
      .catch((error) => {        if (error.response === undefined || null) {
        props.SetPortalMessage({
          error: true,
          content: t("PortalMessages.Error.Server") + " (500).",
        });
      } else if (error.response.status === 401) {
        expirationSession(props, t, navigation);
      } else {
        props.SetPortalMessage({
          error: true,
          content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
        });
      }});
  }

  
  useEffect(() => {
    fncUpdateGrid();
  }, []);
  //------------------------------------------
  return (
    <div id="tp_sidebar_ctr">
      {props.content === "profile" ? (
        <>
          <div id="tp_sidebar_hr">
            <h1>{t("SideBar.Profile.Profile")}</h1>
          </div>
          <div id="tp_sidebar_mn">
            <div className="tp_sidebar_profile">
              <strong>{t("SideBar.Profile.Name")}</strong>
              <p>
                {props.global.loading === true ||
                (props.global.profile.name === null &&
                  props.global.profile.surname === null) ? (
                  <Skeleton color="var(--tp_skeleton_color)" />
                ) : (
                  props.global.profile.name + " " + props.global.profile.surname
                )}
              </p>
            </div>
            <div className="tp_sidebar_profile">
              <strong>{t("SideBar.Profile.Email")}</strong>
              <p>
                {props.global.loading === true ||
                props.global.profile.username === null ? (
                  <Skeleton color="var(--tp_skeleton_color)" />
                ) : (
                  props.global.profile.username
                )}
              </p>
            </div>
            <div className="tp_sidebar_profile">
              <strong>{t("SideBar.Profile.Organization")}</strong>
              <p>
                {props.global.loading === true ||
                props.global.profile.organization === null ? (
                  <Skeleton color="var(--tp_skeleton_color)" />
                ) : (
                  props.global.profile.organization
                )}
              </p>
            </div>
            <div className="tp_sidebar_profile">
              <strong>{t("SideBar.Profile.Role")}</strong>
              <p>
                {props.global.loading === true ||
                props.global.profile.permissions === null ? (
                  <Skeleton color="var(--tp_skeleton_color)" />
                ) : props.global.profile.permissions === 1 ? (
                  "Admin"
                ) : (
                  "SubAdmin"
                )}
              </p>
            </div>

          </div>
          <div id="tp_sidebar_ft">
          <Dropdown
              options={languageOptions}
              selection
              fluid
              defaultValue={props.global.profile.lang}
              onChange={(e, d) => {
                i18n.changeLanguage(d.value);
                props.SetLang(d.value);
              }}
            />
            <Button icon='sign-out' content={t("Button.SignOut")} fluid 
              onClick={() => {
                API_Logout(props.global.username, props.global.token)
                  .then(() => {
                    props.SetUsername("");
                    props.SetToken("");
                    props.SetSideBarContent("");
                    props.SetSideBarVisibile(false);
                    DeleteSession(navigation, true);
                  })
                  .catch(() => {});
              }} />
          </div>
        </>
      ) : props.content === "notify" ? (
        <>
          <div id="tp_sidebar_hr">
            <h1>{t("SideBar.Notifications")}</h1>
          </div>
          
            {props.global.loading === true ||
            props.global.allNotifications === null || props.global.allNotifications.notifications.length < 1 ?
              <p>{t("PortalMessages.NoMessages")}</p> : 
              <div id="tp_sidebar_mn">{fncGetMessages()}</div>}
          
        </>
      ) : props.content === "info" ? (
        <>
          <div id="tp_sidebar_hr">
            <h1>{t("SideBar.Info.Info")}</h1>
          </div>
          <div id="tp_sidebar_mn">
            <h3>{t("SideBar.Info.Apps")}</h3>
            <div className="tp_sidebar_info">
              <span>Outlook Plugin</span>
              <a
                href="https://www.teamspower.cloud/download/#tp-outlook"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <div className="tp_sidebar_info">
              <span>Zimbra Zimlet Old Gen.</span>
              <a
                href="https://www.teamspower.cloud/download/#tp-zimlet"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <div className="tp_sidebar_info">
              <span>Zimbra Zimlet (Current)</span>
              <a
                href="https://www.teamspower.cloud/download/#tp-zimlet"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <h3>{t("SideBar.Info.Documentiation")}</h3>
            <div className="tp_sidebar_info">
              <span>{t("SideBar.Info.UserManual")}</span>
              <a
                href="https://wiki.innovazionedigitale.it/en/TeamsPower/CustomerPortal"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <div className="tp_sidebar_info">
              <span>Outlook Plugin</span>
              <a
                href="https://wiki.innovazionedigitale.it/en/TeamsPower/ZimletAndPluginsSetupAndConfiguration"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <div className="tp_sidebar_info">
              <span>Zimbra Zimlet Old Gen.</span>
              <a
                href="https://wiki.innovazionedigitale.it/en/TeamsPower/ZimletEndUserManual"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
            <div className="tp_sidebar_info">
              <span>Zimbra Zimlet (Current)</span>
              <a
                href="https://wiki.innovazionedigitale.it/en/TeamsPower/ZimletAndPluginsSetupAndConfiguration"
                target="_blank"
                rel="noreferrer"
              >
                                <Icon
                  name="arrow right"
                />
              </a>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

// ----------------------------------------------------------
// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global };
}

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoading: (value) => dispatch(SetLoading(value)),
    SetLang: (value) => dispatch(SetLang(value)),
    SetUsername: (value) => dispatch(SetUsername(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetSideBarContent: (value) => dispatch(SetSideBarContent(value)),
    SetSideBarVisibile: (value) => dispatch(SetSideBarVisibile(value)),
    SetProfile: (value) => dispatch(SetProfile(value)),
    SetNotifications: (value) => dispatch(SetNotifications(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
