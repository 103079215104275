import axios from "axios";

export async function Save(
  username,
  token,
  name,
  surname,
  email,
  password,
  permissions,
  notifications,
  lang
) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/portal-user/save",
    {
      name: name,
      surname: surname,
      username: email,
      password: password,
      permissions: permissions,
      notifications: notifications,
      lang: lang,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function Delete(username, token, email) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/portal-user/delete",
    {
      username: email,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function User(username, token, user) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/portal-user/user",
    {
      username: user,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function Password(username, token, email, password) {
  return axios.post(
    process.env.REACT_APP_API_URI + "/portal-user/password",
    {
      username: email,
      password: password,
    },
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function PagesCount(username, token, filter) {
  return axios.get(process.env.REACT_APP_API_URI + "/portal-user/pages/count?filter=" + filter, {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

export async function Page(
  username,
  token,
  page,
  filter,
  column,
  column_order
) {
  return axios.get(
    process.env.REACT_APP_API_URI +
      "/portal-user/pages?page=" +
      page +
      "&filter=" +
      filter +
      "&sort_column=" +
      column +
      "&sort_order=" +
      column_order,
    {
      headers: {
        Authorization: username + ":" + token,
      },
    }
  );
}

export async function Summary(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/portal-user/summary", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}

export async function Export(username, token) {
  return axios.get(process.env.REACT_APP_API_URI + "/portal-user/export", {
    headers: {
      Authorization: username + ":" + token,
    },
  });
}