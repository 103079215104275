import "./licenses.scss";
import {
  Table,
  Button,
  Search,
  Modal,
  Form,
  Pagination,
  Message,
  Popup,
} from "semantic-ui-react";
import Skeleton from "react-skeleton-loader";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom Functions
import { emailValidate } from "../../lib/emailValidation";
import { normalizedDate } from "../../lib/normalizedDate";
import { expirationSession } from "../../lib/expirationSession";

// API
import {
  License as API_License,
  Save as API_Save,
  Summary as API_Summary,
  Page as API_Page,
  PageCount as API_PageCount,
  Clear as API_Clear,
  Export as API_Export
} from "../../api/licenses";

// Components
import Loading from "../../components/shared/LoadingScreen";
import FileUpload from "../../components/FileUploads";

//  Redux
import { connect } from "react-redux";
import {
  SetUsername,
  SetToken,
  SetPortalMessage,
} from "../../redux/global/actions";
import {
  SetAllRecords,
  SetSummary,
  SetCurrentRecord,
  SetModal,
  SetLicensesFilter,
  SetFilter,
  SetColumnActive,
  SetColumnOrder,
  SetCurrentPage,
  SetTotalPages,
  SetFormMessage,
} from "../../redux/licenses/actions";
import { SetLoginFormError } from "../../redux/login/actions";

// ----------------------------------------------------------------

function Licenses(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  // Check Value
  const isNull = (value) => {
    if (value === null) {
      return (
        <Skeleton
          color="var(--tp_skeleton_color)"
          width="20px"
          widthRandomness="0"
        />
      );
    } else {
      return value;
    }
  };
  // ---------
  
  // API error
  const fncAPIGetError = (error) => {
    if (error.response === undefined || null) {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (500).",
      });
    } else if (error.response.status === 401) {
      expirationSession(props, t, navigation);
    } else {
      props.SetPortalMessage({
        error: true,
        content: t("PortalMessages.Error.Server") + " (" + error.response.status + ").",
      });
    }
  };
  // ---------

  // Change Column Order
  var fncChangeColumnOrder = () => {
    return props.licenses.columnOrder === "asc" ? "desc" : "asc";
  };
  // ---------

  // Get Rows
  const fncGetRows = () => {
    let rows = [];
    props.licenses.allRecords.forEach((element, index) => {
      rows.push(
        <Table.Row key={index}>
          <Table.Cell textAlign="center">
            {element.email === null || element.email.length < 1
              ? "-"
              : element.email}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {element.microsoft_email === null || element.microsoft_email.length < 1
              ? "-"
              : element.microsoft_email}
          </Table.Cell>
          <Table.Cell textAlign="center">{element.api_key}</Table.Cell>
          <Table.Cell textAlign="center">
            {normalizedDate(element.expiration_date)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {element.license_mode === 1
              ? t("Licenses.Direct")
              : t("Licenses.Balanced")}
          </Table.Cell>
          <Table.Cell collapsing>
            <Button
              icon="pencil"
              circular
              onClick={() => fncLicense(element)}
            />
            <Popup
              content={t("Input.Copy")}
              on="click"
              pinned
              trigger={
                <Button
                  icon="copy outline"
                  circular
                  onClick={() => {
                    navigator.clipboard.writeText(element.api_key);
                  }}
                  size="medium"
                />
              }
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return rows;
  };
  // ---------

  // -------------------------------

  const fncUpdateGrid = () => {
    // Summary
    API_Summary(
      props.global.username,
      props.global.token
      )
      .then((body) => {
        props.SetSummary(body.data);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    // Page
    API_Page(
      props.global.username,
      props.global.token,
      props.licenses.currentPage,
      props.licenses.licensesFilter,
      props.licenses.filter,
      props.licenses.columnActive,
      props.licenses.columnOrder
    )
      .then((body) => {
        props.SetAllRecords(body.data.licenses);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });

    // Page Count
    API_PageCount(
      props.global.username,
      props.global.token,
      props.licenses.filter,
      props.licenses.licensesFilter
    )
      .then((body) => {
        props.SetTotalPages(body.data.pages_count);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });
  };

  // Save
  const fncSave = (email, microsoftemail) => {
    API_Save(
      props.global.username,
      props.global.token,
      props.licenses.currentRecord.api_key,
      email,
      microsoftemail
    )
      .then(() => {
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Saved"),
        });
        props.SetModal(false);
        fncUpdateGrid();
        props.SetFormMessage(null);
      })
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetFormMessage(t("PortalMessages.Error.EmailExist"));
        }
      });
  };

  // Clear
  const fncClear = (api_key) => {
    API_Clear(
      props.global.username,
      props.global.token,
      api_key
      )
      .then(() => {
        props.SetPortalMessage({
          error: false,
          content: t("Actions.Unlinked"),
        });
        props.SetModal(false);
        fncUpdateGrid();
        props.SetFormMessage(null);
      })
      .catch((error) => {
        if (error.response === undefined || null) {
          props.SetFormMessage(t("PortalMessages.Error.Server") + " (500).");
        } else if (error.response.status === 401) {
          expirationSession(props, t, navigation);
        } else {
          props.SetFormMessage("Error: " + error.response.data.error_message);
        }
      });
  };

  // License
  const fncLicense = (element) => {
    API_License(props.global.username, props.global.token, element.api_key)
      .then((body) => {
        props.SetCurrentRecord(body.data);
        props.SetModal(true);
      })
      .catch((error) => {
        fncAPIGetError(error);
      });
  };

  // Export
  const fncExport = () => {
    API_Export(props.global.username, props.global.token)
    .then((body) => {
      window.location.replace(process.env.REACT_APP_API_URI + body.data.url)
    })
    .catch((error) => {
      fncAPIGetError(error);
    });
  }

  useEffect(() => {
    fncUpdateGrid();
  }, [
    props.licenses.licensesFilter,
    props.licenses.filter,
    props.licenses.currentPage,
    props.licenses.columnActive,
    props.licenses.columnOrder,
  ]);

  // -------------------------------------------------------------------

  if (props.licenses === undefined || props.licenses === null) {
    return <Loading />;
  } else {
    return (
      <div id="tp_licenses_ctr">
        <div id="tp_managebar_ctr">
          <div id="tp_filters_ctr">
            <div
              className={
                props.licenses.licensesFilter === 0
                  ? "tp_filter_btn_active"
                  : "tp_filter_btn"
              }
              onClick={() => props.SetLicensesFilter(0)}
            >
              <strong>{t("Licenses.ViewAll")}</strong>
            </div>

            <div
              className={
                props.licenses.licensesFilter === 1
                  ? "tp_filter_btn_active"
                  : "tp_filter_btn"
              }
              onClick={() => props.SetLicensesFilter(1)}
            >
              <strong>{t("Licenses.Direct")}</strong>
              <p>{isNull(props.licenses.summary.direct_licenses_count)}</p>
            </div>

            <div
              className={
                props.licenses.licensesFilter === 2
                  ? "tp_filter_btn_active"
                  : "tp_filter_btn"
              }
              onClick={() => props.SetLicensesFilter(2)}
            >
              <strong>{t("Licenses.Balanced")}</strong>
              <p>{isNull(props.licenses.summary.balanced_licenses_count)}</p>
            </div>

            <div
              className={
                props.licenses.licensesFilter === 3
                  ? "tp_filter_btn_active"
                  : "tp_filter_btn"
              }
              onClick={() => props.SetLicensesFilter(3)}
            >
              <strong>{t("Licenses.Free")}</strong>
              <p>{isNull(props.licenses.summary.free_licenses_count)}</p>
            </div>
          </div>
          <div id="tp_btn_ctr">
            <Search
              placeholder={t("Input.Search")}
              size="large"
              defaultValue={props.licenses.filter}
              showNoResults={false}
              onSearchChange={(e, d) => {
                props.SetFilter(d.value);
              }}
            />
            <Button icon="upload" disabled={true} size="large" circular onClick={()=>{document.querySelector("#tp_licenses_import").click()}}/>
            <Button icon="file excel outline" size="large" circular onClick={() => fncExport()}/>
          </div>
        </div>
        <div id="tp_table_ctr">
          {props.licenses.allRecords === null ? (
            <Loading />
          ) : (
            <Table selectable sortable unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.licenses.columnActive === "email"
                        ? props.licenses.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("email");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("Licenses.Email")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.licenses.columnActive === "microsoft_email"
                        ? props.licenses.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("microsoft_email");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("Licenses.MicrosoftEmail")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.licenses.columnActive === "api_key"
                        ? props.licenses.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("api_key");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("Licenses.ApiKey")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.licenses.columnActive === "expiration_date"
                        ? props.licenses.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("expiration_date");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("Licenses.ExpirationDate")}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={
                      props.licenses.columnActive === "license_mode"
                        ? props.licenses.columnOrder === "asc"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      props.SetColumnActive("license_mode");
                      props.SetColumnOrder(fncChangeColumnOrder());
                    }}
                  >
                    {t("Licenses.LicenseMode")}
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>{fncGetRows()}</Table.Body>

              {props.licenses.totalPages > 1 ? (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="6" textAlign="right">
                      <Pagination
                        defaultActivePage={props.licenses.currentPage}
                        totalPages={props.licenses.totalPages}
                        onPageChange={(e, d) =>
                          props.SetCurrentPage(d.activePage)
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              ) : null}
            </Table>
          )}
        </div>
        <FileUpload/>

        {/* ---------------------------------------------------------- */}

        <Modal
          onClose={() => props.SetModal(false)}
          open={props.licenses.modal}
        >
          <Modal.Header>{t("Licenses.EditLicense")}</Modal.Header>
          <Modal.Content>
            {props.licenses.formMessage !== null ? (
              <Message error content={props.licenses.formMessage} />
            ) : null}
            <Form>
              <div className="tp_disabled">
                <div>
                  <strong>{t("Licenses.ApiKey")}</strong>
                  <p>{props.licenses.currentRecord.api_key}</p>
                  <strong>{t("Licenses.LicenseMode")}</strong>
                  <p>
                    {props.licenses.currentRecord.license_mode === 1
                      ? t("Licenses.Direct")
                      : t("Licenses.Balanced")}
                  </p>
                  <strong>{t("Licenses.ExpirationDate")}</strong>
                  <p>
                    {normalizedDate(
                      props.licenses.currentRecord.expiration_date
                    )}
                  </p>
                </div>
                {props.licenses.currentRecord.is_free === false ? (
                  <Button
                    icon="unlink"
                    content={t("Licenses.UnlinkLicense")}
                    circular
                    onClick={() => {
                      fncClear(props.licenses.currentRecord.api_key);
                    }}
                  />
                ) : null}
              </div>

              <Form.Input
                label={t("Licenses.Email")}
                defaultValue={props.licenses.currentRecord.email}
                required
                id="tp_email"
                onChange={()=>props.SetFormMessage(null)}
              />
              {props.licenses.currentRecord.license_mode === 1 ? (
                <Form.Input
                  label={t("Licenses.MicrosoftEmail")}
                  defaultValue={props.licenses.currentRecord.microsoft_email}
                  required
                  id="tp_microsoftemail"
                  onChange={()=>props.SetFormMessage(null)}
                />
              ) : null}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content={t("Button.Cancel")}
              onClick={() => {
                props.SetModal(false);
                props.SetFormMessage(null);
              }}
            />
            <Button
              content={t("Button.Save")}
              className="tp_btn"
              onClick={() => {
                var email = document.querySelector("#tp_email").value.trim();
                if (props.licenses.currentRecord.license_mode === 1) {
                  var microsoftemail = document.querySelector("#tp_microsoftemail").value.trim();
                  if (email && microsoftemail) {
                    if (emailValidate(email) && emailValidate(microsoftemail)) {
                      fncSave(email, microsoftemail);
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.MailNotValid"));
                    }
                  } else {
                    props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                  }
                } else {
                  if (email) {
                    if (emailValidate(email)) {
                      fncSave(email, "");
                    } else {
                      props.SetFormMessage(t("PortalMessages.Error.MailNotValid"));
                    }
                  } else {
                    props.SetFormMessage(t("PortalMessages.Error.FieldsEmpty"));
                  }
                }
              }}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
    
  }
}

// ----------------------------------------------------------------

// Mapping Hook
function mapStateToProps(state) {
  return { global: state.global, licenses: state.licenses };
}
const mapDispatchToProps = (dispatch) => {
  return {
    SetUsername: (value) => dispatch(SetUsername(value)),
    SetToken: (value) => dispatch(SetToken(value)),
    SetPortalMessage: (value) => dispatch(SetPortalMessage(value)),

    SetLoginFormError: (value) => dispatch(SetLoginFormError(value)),

    SetAllRecords: (value) => dispatch(SetAllRecords(value)),
    SetSummary: (value) => dispatch(SetSummary(value)),
    SetCurrentRecord: (value) => dispatch(SetCurrentRecord(value)),
    SetModal: (value) => dispatch(SetModal(value)),
    SetLicensesFilter: (value) => dispatch(SetLicensesFilter(value)),
    SetFilter: (value) => dispatch(SetFilter(value)),
    SetColumnActive: (value) => dispatch(SetColumnActive(value)),
    SetColumnOrder: (value) => dispatch(SetColumnOrder(value)),
    SetCurrentPage: (value) => dispatch(SetCurrentPage(value)),
    SetTotalPages: (value) => dispatch(SetTotalPages(value)),
    SetFormMessage: (value) => dispatch(SetFormMessage(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Licenses);
