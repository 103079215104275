import Skeleton from "react-skeleton-loader";

function Loading() {
  return (
    <div id="tp_loading_ctr">
      <Skeleton
        height="10px"
        width="85px"
        color="var(--tp_skeleton_color)"
        widthRandomness="0"
      />
      <Skeleton
        height="10px"
        width="100px"
        color="var(--tp_skeleton_color)"
        widthRandomness="0"
      />
      <Skeleton
        height="10px"
        width="60px"
        color="var(--tp_skeleton_color)"
        widthRandomness="0"
      />
    </div>
  );
}

export default Loading;
