export const languageOptions = [
  {
    key: "English",
    text: "English",
    value: "en",
    flag: "us",
  },
  {
    key: "Italiano",
    text: "Italiano",
    value: "it",
    flag: "it",
  },
  {
    key: "Français",
    text: "Français",
    value: "fr",
    flag: "fr",
  },
];
