import { SET_FORM_ERROR, SET_VIEW_FORGOTPASSWORD } from "./actions";

const STATE = {
  formError: {
    errorStatus: null,
    content: null,
  },
  viewForgotPassword: false,
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_FORM_ERROR:
      return Object.assign({}, state, { formError: actMsg.data });

    case SET_VIEW_FORGOTPASSWORD:
      return Object.assign({}, state, { viewForgotPassword: actMsg.data });

    default:
      return state;
  }
}
