import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// SCSS
import "./index.scss";
import "./lib/styles/card.scss";
import "./lib/styles/navbar.scss";
import "./lib/styles/sidebar.scss";
import "./lib/styles/managebar.scss";

// i18n
import { Seti18nReady } from "./i18n";

// Views
import Template from "./Template";
import Login from "./views/Login/login";
import Dashboard from "./views/Dashboard/dashboard";
import Tenant from "./views/Tenant/tenant";
import Licenses from "./views/Licenses/licenses";
import PortalUsers from "./views/PortalUsers/portalusers";

Seti18nReady(() => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route exact path="/Login" element={<Login />}></Route>

            <Route
              exact
              path="/"
              element={
                <Template
                  main_component={<Dashboard />}
                  current_view="Dashboard"
                />
              }
            ></Route>
            <Route
              exact
              path="/Tenant"
              element={
                <Template main_component={<Tenant />} current_view="Tenant" />
              }
            ></Route>
            <Route
              exact
              path="/Licenses"
              element={
                <Template
                  main_component={<Licenses />}
                  current_view="Licenses"
                />
              }
            ></Route>
            <Route
              exact
              path="/PortalUsers"
              element={
                <Template
                  main_component={<PortalUsers />}
                  current_view="PortalUsers"
                />
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  );
});

reportWebVitals();
