import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

var fnci18nready = undefined;
export function Seti18nReady(fnc) {
  fnci18nready = fnc;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.i18nextLng || "it",
    debug: false,

    react: { wait: true, useSuspense: false },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {
    if (fnci18nready !== undefined) {
      fnci18nready();
    }
  });

export default i18n;
