export const normalizedDate = (date) => {
  if (date === -1) {
    return "-";
  } else {
    var new_date = new Date(date * 1000);
    return (
      new_date.getDate().toString().padStart(2, "0") +
      "/" +
      (new_date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new_date.getFullYear().toString()
    );
  }
};