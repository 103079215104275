import React from "react";
import axios from "axios";

const FileUpload = () => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(process.env.REACT_APP_API_URI + "/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      })
      .then((body) => {
	
      })
      .catch((error) => {
    
      });
  };
  return (
    <div>
      <input type="file" onChange={handleFileUpload} style={{display:"none"}} id="tp_licenses_import" />
    </div>
  );
};
export default FileUpload;