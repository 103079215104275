import {
  SET_ALL_RECORDS,
  SET_SUMMARY,
  SET_CURRENT_RECORD,
  SET_MODAL,
  SET_LICENSES_FILTER,
  SET_FILTER,
  SET_COLUMN_ACTIVE,
  SET_COLUMN_ORDER,
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGES,
  SET_FORM_MESSAGE,
} from "./actions";

const STATE = {
  allRecords: null,
  summary: {
    balanced_licenses_count: null,
    direct_licenses_count: null,
    free_licenses_count: null,
  },
  currentRecord: {
    email: null,
    microsoft_email: null,
    api_key: null,
    creation_date: null,
    expiration_date: null,
    license_mode: 0,
    is_free: false,
  },
  modal: false,
  licensesFilter: 0,
  filter: "",
  columnActive: "",
  columnOrder: "",
  currentPage: 1,
  totalPages: null,
  formMessage: null,
};

export default function Reducer(state = STATE, actMsg) {
  switch (actMsg.type) {
    case SET_ALL_RECORDS:
      return Object.assign({}, state, { allRecords: actMsg.data });

    case SET_SUMMARY:
      return Object.assign({}, state, { summary: actMsg.data });

    case SET_CURRENT_RECORD:
      return Object.assign({}, state, { currentRecord: actMsg.data });

    case SET_MODAL:
      return Object.assign({}, state, { modal: actMsg.data });

    case SET_LICENSES_FILTER:
      return Object.assign({}, state, { licensesFilter: actMsg.data });

    case SET_FILTER:
      return Object.assign({}, state, { filter: actMsg.data });

    case SET_COLUMN_ACTIVE:
      return Object.assign({}, state, { columnActive: actMsg.data });

    case SET_COLUMN_ORDER:
      return Object.assign({}, state, { columnOrder: actMsg.data });

    case SET_CURRENT_PAGE:
      return Object.assign({}, state, { currentPage: actMsg.data });

    case SET_TOTAL_PAGES:
      return Object.assign({}, state, { totalPages: actMsg.data });

    case SET_FORM_MESSAGE:
      return Object.assign({}, state, { formMessage: actMsg.data });

    default:
      return state;
  }
}
