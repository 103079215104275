export const SET_TENANT_INFO = "DASHBOARD.SET_TENANT_INFO";
export function SetTenantInfo(value) {
  return { type: SET_TENANT_INFO, data: value };
}

export const SET_LICENSES_INFO = "DASHBOARD.SET_LICENSES_INFO";
export function SetLicensesInfo(value) {
  return { type: SET_LICENSES_INFO, data: value };
}

export const SET_PORTAL_USERS_INFO = "DASHBOARD.SET_PORTAL_USERS_INFO";
export function SetPortalUsersInfo(value) {
  return { type: SET_PORTAL_USERS_INFO, data: value };
}

export const SET_USER_INFO = "DASHBOARD.SET_USER_INFO";
export function SetUserInfo(value) {
  return { type: SET_USER_INFO, data: value };
}
