export const SET_ALL_RECORDS = "LICENSES.SET_ALL_RECORDS";
export function SetAllRecords(value) {
  return { type: SET_ALL_RECORDS, data: value };
}

export const SET_SUMMARY = "LICENSES.SET_SUMMARY";
export function SetSummary(value) {
  return { type: SET_SUMMARY, data: value };
}

export const SET_CURRENT_RECORD = "LICENSES.SET_CURRENT_RECORD";
export function SetCurrentRecord(value) {
  return { type: SET_CURRENT_RECORD, data: value };
}

export const SET_MODAL = "LICENSES.SET_MODAL";
export function SetModal(value) {
  return { type: SET_MODAL, data: value };
}

export const SET_LICENSES_FILTER = "LICENSES.SET_LICENSES_FILTER";
export function SetLicensesFilter(value) {
  return { type: SET_LICENSES_FILTER, data: value };
}

export const SET_FILTER = "LICENSES.SET_FILTER";
export function SetFilter(value) {
  return { type: SET_FILTER, data: value };
}

export const SET_COLUMN_ACTIVE = "LICENSES.SET_COLUMN_ACTIVE";
export function SetColumnActive(value) {
  return { type: SET_COLUMN_ACTIVE, data: value };
}

export const SET_COLUMN_ORDER = "LICENSES.SET_COLUMN_ORDER";
export function SetColumnOrder(value) {
  return { type: SET_COLUMN_ORDER, data: value };
}

export const SET_CURRENT_PAGE = "LICENSES.SET_CURRENT_PAGE";
export function SetCurrentPage(value) {
  return { type: SET_CURRENT_PAGE, data: value };
}

export const SET_TOTAL_PAGES = "LICENSES.SET_TOTAL_PAGES";
export function SetTotalPages(value) {
  return { type: SET_TOTAL_PAGES, data: value };
}

export const SET_FORM_MESSAGE = "LICENSES.SET_FORM_MESSAGE";
export function SetFormMessage(value) {
  return { type: SET_FORM_MESSAGE, data: value };
}
